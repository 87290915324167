@import "config";
// Colors
$capripedia: #4a90e2;
$secondary: #2c4a88;
$gray: #9e9e9e;
$gray-e1: #e1e1e3;
$gray-ed: #ededed;
$gray-97: #979797;
$gray-text: #8e9092;
$gray-text-hover: #7a7a7c;
$register-bg: #dde6fa;
$sidebar-bg: #f4f7fa;
$list-color: $gray-text;
$border-color: #dfdfdf;
$light-f5: #f5f5f5;
$black: #333333;
$grey-dark: #444444;
$branding: #333333;
$tool-primary: #00dc95;
$ovipedia: #00a699;
$lactipedia: #1b3869;
$newsdesk: #1b3869;
$farmnotes: #ea8d28;
$admin: #303641;

$primary: null !default;

@if $product == "ovipedia" {
  $primary: $ovipedia;
} @else if $product == "lactipedia" {
  $primary: $lactipedia;
} @else if $product == "capripedia" {
  $primary: $capripedia;
} @else {
  $primary: red;
}

// Backgrounds
$color-danger: rgb(220, 53, 69);
$body-bg: #fff;
$body-text: #3b3f4b;

// Typography
$base-font: "Open Sans", sans-serif;
$base-size: 16px;
$line-height: 1.5;

$p1: 0.5rem;
$p2: 1rem;
$p3: 2rem;

$separator-line: 0.3px solid $gray-97;

$content-box-shadow: rgba(0, 0, 0, 0.16) 0 1px 4px;
$content-box-shadow-hover: rgba(0, 0, 0, 0.16) 0 1px 6px;
