.decision-tree-cont {
  padding: 0 $p3;
  .description {
    padding-top: $p2;
  }
  .decision-tree {
    .level {
      display: flex;
      .node {
        box-shadow: $content-box-shadow;
        margin: $p1;
        padding: $p1;
        display: flex;
        align-items: center;
        font-weight: bolder;
        cursor: pointer;
        svg {
          margin-right: $p1;
          width: 1em;
        }
        &.leaf {
          border-bottom: 2px solid $primary;
          cursor: initial;
        }
        &:hover {
          box-shadow: $content-box-shadow-hover;
          &.leaf {
            box-shadow: $content-box-shadow;
          }
        }
        &.active {
          border-bottom: 2px solid $primary;
          color: $primary;
          background-color: $gray-ed;
        }
        &.non-active {
          color: $gray;
        }
      }
    }
  }
}
