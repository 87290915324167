.title-save-cont {
  align-items: flex-start;
  display: flex;
  flex: 0 0 100%;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-bottom: $p2;
  .edit-title {
    flex: 0 0 100%;
    @include media-breakpoint-up(md) {
      flex: 0 0 50%;
    }
  }
  .buttons-cont {
    flex: 0 0 100%;
    display: flex;
    justify-content: center;
    margin-top: $p2;
    @include media-breakpoint-up(md) {
      flex: 0 0 50%;
      margin-top: 0;
      justify-content: flex-end;
    }
    .btn {
      margin-left: $p2;
    }
  }
}
.edit-article {
  max-width: 80rem;
  margin: 0 auto;
  .article-head {
    position: relative;
    .alert {
      margin-left: 0 !important;
    }
  }

  .edit-body {
    padding: $p3;
    //display: flex;
    .metadata {
      display: flex;
      flex-wrap: wrap;
      .input-cont {
        margin-right: 0;
        flex: 0 0 100%;
        @include media-breakpoint-up(md) {
          flex: 0 0 50%;
        }
        &.expert-picker-cont {
          .form-label {
            display: block;
          }
          @include media-breakpoint-up(md) {
            padding-left: $p2;
            .form-label {
              display: none;
            }
          }

          .expert-picker {
            .list {
              list-style-type: none;
              box-shadow: $content-box-shadow;
              padding: 0;
              li {
                border-bottom: 1px solid $border-color;
                &:last-of-type {
                  border-bottom: none;
                }
                padding: $p1;
                margin: 0;

                display: flex;
                justify-content: space-between;
                .labels {
                  display: flex;
                  align-items: center;
                  svg {
                    cursor: pointer;
                    margin-right: $p1;
                    color: $color-danger;
                    // color: red;
                  }
                }
                .move-icons {
                  display: flex;
                  margin: -$p1;
                  margin-right: $p1;
                  flex-direction: column;
                  justify-content: center;
                  cursor: pointer;
                  color: $gray-text;
                  font-weight: bolder;
                  svg:hover {
                    color: black;
                  }
                  .disabled,
                  .disabled:hover {
                    color: $gray-e1;
                  }
                }
              }
            }
          }
        }
        .form-group {
          margin-bottom: $p2;
        }
      }
    }

    .add-chapter-btn {
      text-decoration: none;
      font-size: 1.4em;
    }

    .chapter-titles-cont {
      @include media-breakpoint-down(md) {
        font-size: 0.8rem;
      }
    }
  }
  .alert {
    margin: $p2 $p3 0 $p3;
  }
}
.chapter-edit {
  margin-bottom: $p2;
  .edit-chapter-title-cont {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .form-group {
      flex: 0 0 100%;
      margin-bottom: $p2;
      display: flex;
      &.free {
        justify-content: flex-end;
      }
      @include media-breakpoint-up(md) {
        flex: 0 0 50%;
      }
    }
  }
  .text-editor {
    .ql-container,
    .ql-editor {
      min-height: 20rem;
    }

    margin-bottom: $p2;
  }
}
.image-upload {
  display: flex;
  margin: 0 (-$p2);
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  @include media-breakpoint-up(sm) {
    justify-content: start;
  }
  .article-image {
    //flex: 0 0 15rem;
    flex: 0 0 15rem;
    min-height: 15rem;
    &.disabled {
      position: relative;
      img {
        cursor: initial;
      }
      &::before {
        content: "";
        position: absolute; /* position the pseudo-element absolutely */
        top: 0; /* top of the div */
        left: 0; /* left of the div */
        width: 100%; /* width of the div */
        height: 100%; /* height of the div */
        background-color: rgba(
          245,
          245,
          245,
          0.324
        ); /* semi-transparent gray */
        z-index: 1;
      }
    }

    img {
      &:active {
        box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.31);
      }
      cursor: pointer;
    }
    @include media-breakpoint-up(sm) {
      flex: 0 0 10rem;
      min-height: 10rem;
    }
    @include media-breakpoint-up(md) {
      flex: 0 0 15rem;
      min-height: 15rem;
    }
    display: flex;
    align-items: center;
    margin: $p2;
  }
  .upload-image-btn {
    flex: 0 0 15rem;
    min-height: 15rem;
    @include media-breakpoint-up(sm) {
      flex: 0 0 10rem;
      min-height: 10rem;
    }
    @include media-breakpoint-up(md) {
      flex: 0 0 15rem;
      min-height: 15rem;
    }

    margin: $p2;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 1px dotted $border-color;
    background: none;
    color: $gray-text;
    &:hover {
      border: 1px dotted $gray-500;
      color: $gray-text-hover;
    }
    &:active {
      border: 1px dotted $gray-800;
      color: $gray-800;
    }
    &:disabled {
      &:hover {
        border: 1px dotted $border-color;
        color: $gray-text;
      }
    }
    svg {
      display: block;
    }
  }
}
.modal-inputs {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  .half-width {
    flex: 0 0 50%;
    display: flex;
    justify-content: flex-end;
    margin-bottom: $p2;
  }
}
