.expert-list {
  display: flex;
  flex-direction: row;
  padding: $p3;
  justify-content: center;
  @include media-breakpoint-up(sm) {
    padding: $p3 2rem;
  }
  @include media-breakpoint-up(md) {
    padding: $p3;
  }

  flex-wrap: wrap;
  width: 100%;
  .expert {
    max-width: 35rem;
    margin: $p2;
    box-sizing: border-box;
    border-radius: $p1;
    display: flex;
    flex-direction: column;

    box-shadow: $content-box-shadow;
    flex: 0 0 calc(100%);
    padding: $p2 $p3;
    @include media-breakpoint-up(md) {
      flex: 0 0 calc(100% / 2 - 2 * $p2);
    }
    @include media-breakpoint-up(xl) {
      flex: 0 0 calc(100% / 3 - 2 * $p2);
    }
    min-height: 30rem;

    flex-wrap: wrap;
    overflow: hidden;
    .expert-head {
      .react-loading-skeleton {
        &.h-2 {
          line-height: 1;
        }
        &.h-3 {
          line-height: 2;
        }
        margin-bottom: $p1;
      }
      display: flex;

      flex-direction: row;
      img {
        border-radius: 50%;
        margin-right: $p2;
      }
      h3 {
        font-weight: 300;
        font-size: 1.5em;
      }
      h4 {
        font-weight: 300;
        font-size: 1.25em;
      }
    }
    .expert-description {
      margin-top: $p2;
      .react-loading-skeleton {
        line-height: 3;
      }
    }
    .expert-contact {
      margin-top: $p2;
      a {
        display: block;
        text-decoration: none;
      }
    }
    .expert-footer {
      background-color: $primary;
      color: white;
      //   align-self: flex-end;
      cursor: pointer;
      justify-self: flex-end;
      text-align: center;
      padding: $p2 $p3;
      margin: -$p3;
      padding-bottom: 2 * $p2;
      margin-top: auto;
      border-bottom-left-radius: $p1;
      box-shadow: $content-box-shadow;
      border-bottom-right-radius: $p1;
      text-decoration: none;
    }
  }
}
