.header {
  position: sticky;
  top: 0;
  min-height: 4rem;
  width: 100%;
  z-index: 11;
  .collapse {
    justify-content: flex-end;
    @include media-breakpoint-up(xl) {
      justify-content: initial;
      &.hide-search {
        justify-content: flex-end;
      }
    }
  }
  .search-bar {
    width: 35%;
  }
  .nav-item {
    color: white;
    justify-self: flex-end;
    .nav-link {
      padding: 0 $p1;
    }
  }
  .language-select {
    color: $gray-100;
    margin-left: $p2;
    .dropdown-toggle::after {
      display:none;
  }
  }
}
