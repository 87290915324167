.article-type {
  display: flex;
  width: 100%;
  min-height: calc(100vh - 4rem);
  justify-content: space-evenly;
  top: 0;
  bottom: 0;
  margin: auto;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  @include media-breakpoint-up(md) {
    position: absolute;
    justify-content: space-evenly;
    flex-direction: row;
  }
  a {
    padding: $p3;
  }
}
.text-editor-invalid {
  border: 1px solid $color-danger;
}
.text-editor-disabled {
  position: relative;
  &::before {
    content: "";
    position: absolute; /* position the pseudo-element absolutely */
    top: 0; /* top of the div */
    left: 0; /* left of the div */
    width: 100%; /* width of the div */
    height: 100%; /* height of the div */
    background-color: rgba(245, 245, 245, 0.668); /* semi-transparent gray */
    z-index: 1;
  }
}

$add-size: 2rem;
$line-color: #f4f7fa;
$option-bg: #f4f7fa;
.decision-tree-editor {
  .level {
    &.collapsed {
      max-height: $add-size;
      overflow: hidden;
    }
    position: relative;
    &:after {
      content: "";
      background-color: $line-color;
      position: absolute;
      top: 0;
      height: calc(100% - $add-size / 2 - 0.5rem);
      left: 0;
      width: 3px;
    }
    .left-cont {
      display: flex;
      position: relative;
      flex-direction: row;
      align-items: center;
    }
    .edit-article-icon {
      margin-left: $p1;
      cursor: pointer;
      path {
        stroke: black;
      }
      &:hover path {
        stroke: $primary;
      }
    }
    .collapse-icon {
      margin-right: $p2;
    }
  }
  padding: $p3 0;

  .node {
    position: relative;
    margin-bottom: $p1;
    &.add {
      cursor: pointer;
      display: inline-block;
      margin-left: $add-size / 2;
      height: $add-size;
      font-size: $add-size / 2;
      line-height: $add-size;
      background-color: #f8f9fa;
      &:hover {
        background-color: #e2e6ea;
      }
      text-align: center;
      width: $add-size;
      font-weight: 400;
      border-radius: 2px;
      &:before {
        content: "";
        position: absolute;
        top: $add-size / 2;
        left: -$add-size / 2;
        width: $add-size / 2;
        background-color: $line-color;
        height: 3px;
      }
    }
    &.article {
      cursor: pointer;
      margin-left: $add-size / 2;
      height: $add-size;
      border: 1px solid $border-color;
      padding-left: $p1;
      line-height: $add-size;
      display: flex;
      align-items: center;
    }
    &.level {
      margin-left: $add-size / 2;
      .text {
        cursor: pointer;
        height: $add-size;
        background-color: $option-bg;
        margin-bottom: $p1;
        padding-left: $p1;
        line-height: $add-size;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }
  }
  .move-btns {
    display: flex;
    font-size: 1.5rem;
    flex-direction: row;
    .disabled,
    .disabled:hover {
      color: $gray-e1;
    }
    svg {
      margin-right: $p1;
      color: $gray-text;
      &:hover {
        color: $primary;
      }
      &:last-child {
        margin-right: $p2;
      }
    }
  }
}
.node-modal {
  .form-group {
    margin-bottom: $p2;
  }
}
