@import "config";
$homeslide: url("../assets/images/homeslide_capripedia.png") !default;

@if $product == "ovipedia" {
  $homeslide: url("../assets/images/homeslide_ovipedia.png");
  $bg-position: top;
} @else if $product == "lactipedia" {
  $homeslide: url("../assets/images/homeslide_lactipedia.png");
} @else if $product == "capripedia" {
  $homeslide: url("../assets/images/homeslide_capripedia.png");
}

.homepage {
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 4em);
  .search-cont {
    position: relative;
    background: $homeslide;
    background-size: cover;
    background-position: center;
    flex: 0 0 40vh;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    .search-bar{
      position: relative;
    }
    &:before {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background: rgba(255, 255, 255, 0.1);
    }
    .search-bar {
      width: 95%;
      @include media-breakpoint-up(md) {
        width: 60%;
      }
      @include media-breakpoint-up(xl) {
        width: 50%;
      }

      z-index: 10;
      input {
        padding: $p2;
        padding-right: 9rem;
      }
    }
    .search-bar-buttons {
      position: absolute;
      right: 0;
      padding-right: $p1;
      top: 0;
      bottom: 0;
      cursor: pointer;
      display: flex;
      align-items: center;
      .search-button {
        background-color: none;
        border: none;
        padding: 0;
        margin: 0;
      }
      .or {
        margin: $p1;
        font-weight: bold;
        color: $gray-500;
      }
      .search-icon {
        font-size: 1.4rem;
        color: $gray-600;
        background-color: none;
        &:hover {
          color: $gray-700;
        }
      }
      
    }
  }
  .buttons-cont {
    flex: 3 0 auto;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    display: flex;
    padding: $p3;
    @include media-breakpoint-up(xl) {
      padding: $p3 10rem;
    }
  }
}
