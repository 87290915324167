.profile {
  h3 {
    flex: 0 0 100%;
    padding: $p2;
    padding-bottom: 0;
    font-size: 1.3em;
  }
  .edit-group {
    margin-top: $p2;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    border: 1px solid $border-color;
    border-radius: $p1;
    padding-bottom: $p2;
    .form-group {
      flex: 0 0 100%;
      &.postal {
        flex: 0 0 40%;
      }
      &.city {
        flex: 0 0 60%;
      }

      @include media-breakpoint-up(md) {
        flex: 0 0 50%;
        &.street {
          flex: 0 0 70%;
        }
        &.number {
          flex: 0 0 30%;
        }
        &.country {
          flex: 0 0 30%;
        }
        &.postal {
          flex: 0 0 20%;
        }
        &.city {
          flex: 0 0 50%;
        }
      }
      padding: $p1 $p2;
    }
  }
  .btn-cont {
    display: flex;
    justify-content: flex-end;
    margin: $p2 0;
    flex-wrap: wrap;
    .btn {
      flex: 0 0 100%;
      margin: $p1 0;
      @include media-breakpoint-up(md) {
        flex: 0 0 20em;
        margin: $p1 0;
      }
    }
  }
}
