.ask-ai-container {
    width: 100%;
    display: flex;
    height: 100%;
    min-height: calc(100vh - 4rem);
    justify-content: center;
    position: relative;
    .coming-soon {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        font-size: 5rem;
        font-weight: bolder;
        margin: auto;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: rgba($color: gray, $alpha: 0.3);
        z-index: 10;
    }
    .chatbox {
        max-width: 50rem;
        width: 100%;
        min-height: calc(100vh - 4rem - 2*$p2);
        display: flex;
        flex-direction: column;
        
        justify-content: space-between;
        .chat {
            padding: $p2 $p3 0 $p3;
            display: flex;
            flex-direction: column;
            .message {
                margin: $p1 0;
                &.user {
                    align-self: end;
                    background-color: $primary;
                    color: white;
                    padding: $p2;
                    
                    border-radius: calc((1.5em + $p1));
                }
                &.system {
                    margin-right: $p3;
                }
            }
        }
        .chat-input {
            width: 100%;
            padding: $p2;
            padding-bottom: $p3;
            textarea {
                border: 2px solid $primary;
                // height: 2.5rem;
                resize:none;
                border-radius: 5px;
                padding: $p1;
                width: 100%;
                padding-left: 2.5rem; /* Space for the upload icon */
                padding-right: 2.5rem; /* Space for the send icon */
                background: white;
                &:active, &:focus {
                    border: 2px solid $primary;
                    border-color: $primary;
                    border-radius: 5px;
                }
            }
            .chat-input-container {
                position: relative;
            }
            .icon {
                position: absolute;
                bottom: 0;
                padding-bottom: 0.8rem;
                color: $gray-600;
                font-size: 1.4rem;
                cursor: pointer;
                &:hover {
                    color: $primary;
                
                }
            }
            .upload-icon {
                padding-left: $p1;
            }
            .send-icon {
                right: $p1;
            }
            .mistake-notice {
                color: $gray-500;
                font-size: 0.7rem;
                margin-top: $p1;
            }
        }
    }
}