#content {
  @include media-breakpoint-up(xl) {
    margin-left: 20%;
  }
  position: relative;
  min-height: calc(100vh - 4rem);
}
.app-cont {
  position: relative;
  min-height: calc(100vh - 4rem);
}
.layout-content {
  min-height: 100%;
  min-width: 100%;
}
html {
  background: $primary;
}
body {
  background: white;
  font-weight: 300;
}
html,
body,
#root,
.app,
.layout-content {
  min-height: 100vh;
}

.no-sidebar-container {
  max-width: 50em;
  padding: $p3;
  margin: auto;
}
