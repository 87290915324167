article {
  height: calc(100vh - 4rem);
  overflow-y: scroll;
  .article-warning {
    margin: $p3;
    margin-bottom: 0;
  }
  .article-head {
    // position: sticky;
    // top: 0;
    background: white;
    padding: $p3 $p3 0 $p3;
    position: relative;
    .icons {
      position: absolute;
      right: $p3;
      top: $p3;
      a {
        color: red;
      }
      .edit-article-icon {
        height: 1.5em;
        width: 1.5em;
        path {
          stroke: $gray-text-hover;
        }
        &:hover path {
          stroke: black;
        }
      }
    }
  }
  .article-body {
    position: relative;
    padding: 0 $p3 $p3 $p3;
    .chapter-titles-cont {
      height: 0px;
      display: flex;
      @include media-breakpoint-up(lg) {
        height: initial;
      }
    }
    .article-content {
      display: flex;
      flex-direction: column;
      .skeleton {
        height: 20em;
        display: block;
      }
      .chapters {
        flex: 0 0 100%;
        a {
          text-decoration: none;
          font-weight: 500;
        }
        &.skeleton {
          margin-right: $p2;
          .react-loading-skeleton {
            height: calc(100% / 3);
          }
        }
        .chapter-content {
          h3 {
            scroll-margin-top: 3rem;
            font-size: 1.5em;
            margin-top: $p3;
          }
          &:first-of-type {
            h3 {
              margin: 0;
              visibility: hidden;
              height: 0px;
            }
          }
        }
      }
      .media {
        flex: 0 0 100%;
        .article-image {
          max-width: 15rem;
          text-align: left;
          float: left;
          @include media-breakpoint-up(md) {
            max-width: 20rem;
            float: none;
          }
        }
        .react-loading-skeleton {
          height: 100%;
        }
      }
      @include media-breakpoint-up(md) {
        flex-direction: row;
        .chapters {
          flex: 5 5 80%;
        }
        .media {
          flex: 3 3 30%;
        }
      }
    }
  }

  .article-paywall {
    border-top: 0.3px solid $gray-500;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 $p3;
    h3 {
      margin: $p3 0 $p2 0;
      font-size: 2rem;
    }
    p {
      margin: $p2 0;
    }
    button {
      padding: $p1 $p2;
    }
  }
}
