.sidebar {
  height: 100%;
  width: 20%;
  background: $sidebar-bg;
  padding: $p3;
  display: flex;
  position: fixed;
  z-index: 10;
  h2 {
    font-size: 1.1rem;
    text-transform: uppercase;
    margin-top: $p3;
    margin-bottom: $p2;
    &:first-of-type {
      margin-top: 0;
    }
    svg {
      vertical-align: top;
      margin-right: $p1;
    }
  }
  .nav {
    flex-direction: column;
    .nav-item {
      display: block;
      position: relative;

      a {
        text-decoration: none;
        color: $gray-97;
        font-weight: 500;

        &:hover {
          text-decoration: underline;
        }

        &.active {
          color: $primary;
          text-decoration: none;
          font-weight: 700;
        }
      }
      .nested-link {
        margin-left: 20px;
        border-left: 1px solid $primary;
        &:before {
          position: absolute;
          content: "";
          background-color: $primary;
          width: 7px;
          height: 7px;
          left: 17px;
          border-radius: 4px;
          top: 50%;
          transform: translateY(-50%);
        }
      }
    }
  }

  .expert {
    display: flex;
    align-items: center;
    .expert-img {
      display: inline-block;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      overflow: hidden;
      img {
        width: 30px;
        min-width: 100%;
        min-height: 100%;
      }
    }
    .expert-info {
      margin-left: 8px;
      display: inline-block;
      a {
        color: $primary;
      }
      h6 {
        font-size: 16px;
        font-weight: 600;
        line-height: normal;
        margin-bottom: 3px;
      }
      p {
        margin-bottom: 0;
        line-height: normal;
        color: $gray-text;
      }
    }
  }
}
