.login {
  min-height: 100vh;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  .login-banner {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: url("./../assets/images/loginback.jpg");
    background-repeat: no-repeat;
    background-size: cover;
  }
  .login-form-cont {
    flex: 0 0 100%;
    @include media-breakpoint-up(sm) {
      flex: 0 0 27rem;
    }
    border-top: 10px solid $primary;
    z-index: 10;
    background-color: white;
    padding: $p3;
    .login-form {
      margin-top: 5rem;
      .form-group {
        &:first-of-type {
          margin-bottom: $p3;
        }
      }
    }
    .forgot-password {
      text-decoration: none;
      color: gray;
      vertical-align: middle;
      font-size: 0.8em;
      cursor: pointer;
    }
    .remember-me {
      margin-top: $p3;
      margin-bottom: $p1;
    }
  }
  .btn {
    width: 100%;
    padding: $p1;
  }
  .no-account {
    margin-top: $p3;
    text-align: center;
    .btn {
      margin-top: $p1;
      &:hover {
        color: white;
      }
    }
  }
}
