.error-page {
  width: 100%;
  position: absolute;
  min-height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  h1 {
    color: $primary;
    font-size: 4em;
    font-weight: 700;
  }
  .btn {
    margin-top: $p2;
  }
}
