.all-articles {
  margin: auto;
  padding: 0 $p3;
  @include media-breakpoint-up(sm) {
    padding: 0 $p1;
  }
  @include media-breakpoint-up(md) {
    padding: 0 $p3;
  }
  @include media-breakpoint-up(xl) {
    max-width: 100rem;
  }
  .filter-cont {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: $p1 0;
    justify-content: space-between;
    .filter-name {
      color: black;
    }
    .filter-select {
      width: 10rem;
      display: inline-block;
    }
  }
}
