.search-results {
  padding: $p3;
  .article-preview {
    * {
      color: black;
      text-decoration: dashed !important;
    }

    display: flex;
    flex-direction: column;
    box-shadow: $content-box-shadow;
    margin-bottom: $p3;
    cursor: pointer;
    &:hover {
      box-shadow: $content-box-shadow-hover;
    }
    padding: $p2;
    .title {
      display: block;
      margin-bottom: $p2;

      h3 {
        display: flex;
        align-items: center;
        svg {
          margin-right: $p1;
        }
      }
      @include media-breakpoint-up(md) {
        display: flex;
        span {
          margin-left: $p1;
        }
      }
      align-items: baseline;
    }
    &.empty-body {
      .title {
        margin-bottom: 0;
      }
    }
  }
}
