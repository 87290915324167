.list-cont {
  .result-count-cont {
    .result-count {
      margin-bottom: $p1;
      height: calc(1.4em + $p1);
      display: block;
    }
    margin: 0 $p3;
    color: $text-muted;
    font-size: 1rem;
    padding: $p3 0 0 0;
  }
  .list {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    flex-direction: row;
    list-style: none;
    padding: 0 $p2;
    .list-item {
      flex: 0 0 100%;
      @include media-breakpoint-up(sm) {
        &.list-4 {
          flex: 0 0 calc(100% / 2);
        }
      }
      @include media-breakpoint-up(md) {
        &.list-4 {
          flex: 0 0 calc(100% / 3);
        }
        flex: 0 0 50%;
      }
      @include media-breakpoint-up(lg) {
        &.list-4 {
          flex: 0 0 calc(100% / 4);
        }
        flex: 0 0 calc(100% / 3);
      }
      padding: $p2;
      box-sizing: border-box;
      .react-loading-skeleton {
        height: calc(1.5em + 2 * $p2);
      }
      .list-link {
        box-shadow: $content-box-shadow;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: $p2;
        text-decoration: none;
        color: $black;
        font-size: 1.2rem;
        &:hover {
          box-shadow: $content-box-shadow-hover;
          color: $black;
        }
        svg {
          margin-right: $p1;
        }
        .badge {
          font-size: 0.5em;
          margin-left: $p1;
          vertical-align: top;
          justify-content: flex-end;
          float: right;
        }
      }
    }
  }
}
