// ChapterNavigation
.chapter-titles-cont {
  background-color: white;
  padding: 0;
  // width: 100%;
  flex-direction: row;
  list-style: none;
  display: flex;
  overflow-x: scroll;
  position: sticky;
  top: 0;
  .chapter-title {
    text-decoration: none;
    cursor: pointer;
    flex-shrink: 0;
    text-transform: uppercase;
    text-align: center;
    color: $primary;
    margin: 0 $p2;
    padding: $p1 0;
    font-weight: 500;
    box-sizing: content-box;
    &:first-of-type {
      margin-left: 0;
    }
    border-top: 2px solid white;
    &.active {
      border-top: 2px solid $primary;
    }
    &.disabled {
      cursor: default;
      color: $text-muted;
    }
    &.invalid {
      color: $color-danger;
      &.active {
        border-top: 2px solid $color-danger;
      }
    }
  }
}

// SectionTitle
.title-cont {
  display: flex;
  align-items: baseline;
  flex-direction: column;
  .skeleton {
    width: 10em;
    display: block;
  }
  h1 {
    font-weight: 400;
  }
  .link-words {
    margin-left: $p1;
    &.skeleton {
      min-width: 10em;
    }
  }
}

// Divider
.divider {
  color: $text-muted;
  font-size: 0.8rem;
  &.align-right {
    text-align: right;
  }
  &.align-left {
    text-align: left;
  }
  &.border-bottom {
    border-bottom: $separator-line;
  }
  &.border-top {
    border-top: $separator-line;
  }
  .skeleton {
    width: 20em;
    display: inline-block;
  }
}

// Article image
.article-image {
  margin: 0 0 $p3 $p3;
  text-align: center;
  border-radius: 3px;

  img {
    box-shadow: 0 0 14px 0 rgba(0, 0, 0, 0.31);
    border-radius: 3px;
    width: 100%;
  }
}

.form-group {
  .required {
    color: rgb(191, 0, 0);
    margin-left: 2px;
  }
  .invalid-message {
    color: rgb(191, 0, 0);
    font-size: 0.8em;
  }
  .info-message {
    font-size: 0.8em;
  }
}

.password-input {
  position: relative;
  .icon {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto $p1;
    font-size: 1.4em;
    color: $gray;
    cursor: pointer;
  }

  input {
    background-image: none !important;
  }
}

.decision-tree-icon {
  rotate: -90deg;
}

.gray-icon-button {
  color: $gray-text;
  cursor: pointer;
  text-decoration: none;
  flex: 0 0 100%;
  //margin: $p3 0;
  @include media-breakpoint-up(md) {
    flex: 0 0 calc(100% / 2);
    //margin: auto;
  }
  display: flex;
  flex-direction: column;
  &:hover {
    color: $gray-text-hover;
    .icon-cont {
      color: $gray-400;
    }
  }
  .icon-cont {
    flex: 0 0 5rem;
    color: $gray-300;
    margin-bottom: $p2;
    svg {
      width: 100%;
      height: 5rem;
      @include media-breakpoint-up(xl) {
        height: 7rem;
      }
    }
  }
  h3 {
    text-align: center;
    font-weight: 400;
  }
}

.loading-button {
  position: relative;
  .spinner-border {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    margin: auto;
  }
}
